<template>
  <b-card-actions
    ref="cardA"
    title="Monthly PK Report"
    action-collapse
    no-body
  >
    <b-card class="mb-2" no-body>
      <div class="mx-1">
        <b-row>
          <b-col cols="12" style="height: calc(100vh - 240px); overflow: hidden;">
            <iframe
              src="https://docs.google.com/spreadsheets/d/1Dpa4aeVt4oL6Ro5hA5elFXyGd4DunY0Hy6dMDVnYkdo/edit?usp=sharing"
              style="width: inherit; height: inherit;"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card-actions>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCard,
    BCardActions,
  },
}
</script>
